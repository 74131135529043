import React from "react";
import { Grid, Layout } from "~components";

const NotFoundPage = () => (
  <>
    <Layout>
      <Grid>
        {/* <section css={[tw`col-span-12 my-8`]}>
          <T.Head>404</T.Head>
        </section> */}
      </Grid>
    </Layout>
  </>
);

export default NotFoundPage;
